import * as React from 'react';
import {
  Button,
  Box,
  Typography,
  Logo,
  styled,
  Divider,
  Paper,
  Stack,
  Link,
} from '@worthy-npm/worthy-common-ui-components';
import { useEffect, useState } from 'react';
import { InfoMail } from '../data/objects/env';
import { useAppSelector, useSmallerVersion } from '../app/hooks';
import { selectCaratClassification, selectItemType } from '../slices/submitSlice';
import { Step, StepContainer } from '../styles/common';
import { directToItemTypePage, driectToHomePage } from '../lib/commonUtils';
import GA from '../data/GA';
import { CLASSIFICATION } from '../data/ItemDetails';

const MinCaratPerType = {
  [CLASSIFICATION.NATURAL]: 0.99,
  [CLASSIFICATION.LAB_GROWN]: 3,
  [CLASSIFICATION.GEMSTONE]: 2,
};

const TypePlural = {
  [CLASSIFICATION.NATURAL]: 'natural diamonds',
  [CLASSIFICATION.LAB_GROWN]: 'lab-grown diamonds',
  [CLASSIFICATION.GEMSTONE]: 'gemstones',
};

const InfoBoxWrap = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  padding: theme.spacing(2),
}));

const mailTo = `mailto:${InfoMail}`;

const SuccessFormSubmit = styled('img')`
  width: 26px;
  height: 32px;
  margin-right: 16px;
`;

const Container = styled('div')`
  text-align: center;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: auto;
    padding: 0 28px;
  }
`;

const ActionsContainer = styled('div')`
  display: flex;
  width: 100%;
  margin: 27px auto 56px auto;
  gap: 48px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
`;

const StyleWrap = styled('div')`
  position: absolute;
  justify-content: center;
  left: 0;
  z-index: 1;
  overflow: auto;
  max-height: 100%;
  min-width: 100%;
  width: 100%;
`;

const NoWrap = styled('span')`
  white-space: nowrap;
`;

const SideBox = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 900px) {
    align-items: center;
  }
`;

const getText = (itemTypeLower: string, classification?: CLASSIFICATION) => {
  const classificationText = classification ? TypePlural[classification] : 'diamonds';

  return (
    <Typography variant="button" textTransform="none" maxWidth="740px">
      {['loose diamond', 'ring'].includes(itemTypeLower) ? (
        <Typography>
          {classification === CLASSIFICATION.LAB_GROWN ? (
            <>
              Worthy does not accept lab-grown diamonds of any kind.
              <br />
              Although your {itemTypeLower} is not eligible for auction, our goal is still to help
              you get the best offer.
            </>
          ) : (
            <>
              Worthy’s auction minimum for <NoWrap>{classificationText}</NoWrap> is currently&nbsp;
              {classification ? MinCaratPerType[classification] : 0.99} carats. Although your&nbsp;
              {itemTypeLower} is not eligible for auction, our goal is still to help you get the
              best offer.
            </>
          )}
          <br />
          <br />
          Our trusted partner Cash for Gold USA could be a great selling option for items like
          yours. Request free shipping and get cash for your valuables within 24 hours. It’s a
          private transaction that’s safe, risk-free and easy.
        </Typography>
      ) : (
        <Typography>
          Based on the description provided, your {itemTypeLower} is not eligible for Worthy’s
          auction process.
          <br />
          <br />
          Our goal is still to help you get the best offer, and our trusted partner Cash for Gold
          USA could be a great selling option for items like yours. Request free shipping and get
          cash for your jewelry within 24 hours. It’s a private transaction that’s safe, risk-free
          and easy.
        </Typography>
      )}
    </Typography>
  );
};

export default function ItemRejection({ oldStyle }: { oldStyle?: boolean }) {
  const itemType = useAppSelector(selectItemType);
  const classification = useAppSelector(selectCaratClassification);
  const itemTypeLower = itemType.toLowerCase();
  const showHeader = !oldStyle;
  const isMobile = useSmallerVersion();

  useEffect(() => {
    GA.autoRejectionView('ringSubmissionFunnel', 'rejectionStepView');
  }, []);

  return (
    <Box
      style={!showHeader ? { position: 'fixed' } : {}}
      width="100%"
      height="100%"
      top="0"
      left="0"
      zIndex="5"
      overflow="auto"
    >
      {!showHeader && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="32px 0"
        >
          <Box onClick={driectToHomePage} style={{ cursor: 'pointer' }}>
            <Logo />
          </Box>
        </Box>
      )}
      <StyleWrap style={showHeader ? { height: '100%' } : { top: '13%' }}>
        <StepContainer data-automation="item-rejection">
          <Step id="step">
            <Container>
              <Typography variant="body1" fontWeight="bold" color="highlight.main">
                We&apos;re sorry
              </Typography>
              <Typography variant="h2" marginBottom={5}>
                We can’t auction your {itemTypeLower},{isMobile ? ' ' : <br />}but you still have
                options
              </Typography>
            </Container>
            <Box display="block" maxWidth="782px" margin="27px auto auto">
              <ActionsContainer>
                <SideBox>{getText(itemTypeLower, classification)}</SideBox>

                <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />

                <SideBox>
                  <Typography
                    marginBottom="8px"
                    fontSize="1rem"
                    maxWidth="320px"
                    textAlign={isMobile ? 'center' : 'initial'}
                  >
                    Get an extra 10% bonus on your quote when you sell gold, silver, diamonds,
                    platinum, and more.
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    color="highlight"
                    disableElevation
                    sx={{ height: '52px', padding: 0, maxWidth: '320px' }}
                    onClick={() => {
                      GA.cashForGold();
                      window.open('http://www.cashforgoldusa.com/?cid=601', '_blank');
                    }}
                  >
                    <Typography variant="button" textTransform="none">
                      Go to Cash for Gold USA
                    </Typography>
                  </Button>
                  <Typography
                    variant="body2"
                    marginTop="8px"
                    fontSize="16px"
                    color="#7A8994"
                    maxWidth="320px"
                    textAlign={isMobile ? 'center' : 'initial'}
                  >
                    You will be taken to a third-party website.
                  </Typography>
                  <Button
                    variant="outlined"
                    disableElevation
                    fullWidth
                    size="large"
                    sx={{
                      height: '52px',
                      padding: 0,
                      maxWidth: '320px',
                      marginTop: '40px',
                    }}
                    onClick={() => {
                      GA.submitNewItem();
                      directToItemTypePage();
                    }}
                  >
                    <Typography variant="button" textTransform="none">
                      Submit a different item to Worthy
                    </Typography>
                  </Button>
                </SideBox>
              </ActionsContainer>

              <Stack alignItems="center">
                <Typography variant="h4" mb={2}>
                  Still Have More Questions?
                </Typography>

                <InfoBoxWrap elevation={0}>
                  <Typography variant="body1" fontWeight="bold" color="divider" marginBottom={1}>
                    Reach us at:
                  </Typography>
                  <Stack direction="row">
                    <Link href="tel:+18882220208">
                      <Typography variant="body1" color="tritary.main">
                        1 (888) 222-0208
                      </Typography>
                    </Link>
                    <Typography variant="body1" color="divider">
                      &nbsp;or&nbsp;
                    </Typography>
                    <Link href={mailTo}>
                      <Typography variant="body1" color="tritary.main">
                        by email
                      </Typography>
                    </Link>
                  </Stack>
                </InfoBoxWrap>
              </Stack>
            </Box>
          </Step>
        </StepContainer>
      </StyleWrap>
    </Box>
  );
}

ItemRejection.defaultProps = {
  oldStyle: false,
};
