import React from 'react';
import { Button, Icon, Stack, Typography, styled } from '@worthy-npm/worthy-common-ui-components';
import { ButtonProps } from '@worthy-npm/worthy-common-ui-components/components/Button/Button';

const StyledCTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.highlight.main,
  color: theme.palette.highlight.contrastText,
  padding: theme.spacing(2, 3),
  '&:hover': {
    backgroundColor: theme.palette.highlight.dark,
  },
  '&:active': {
    backgroundColor: theme.palette.highlight.light,
  },
}));

export default function CTAButton(props: ButtonProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledCTAButton {...props} variant="contained" disableElevation>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body1" noWrap>
          Continue
        </Typography>
        <Icon.ExpandMoreShineIcon sx={{ height: 15, transform: 'rotate(270deg)' }} />
      </Stack>
    </StyledCTAButton>
  );
}
