import axios from 'axios';
import { PROMOTION_NAME } from '../data/promotion';

const { REACT_APP_ZAPIER_PROMOTION_WEBHOOK } = process.env;

export default class ZapierAPI {
  static async registerToPromotion(email: string) {
    return axios
      .post(
        `${REACT_APP_ZAPIER_PROMOTION_WEBHOOK}`,
        {
          email,
          promotionName: PROMOTION_NAME,
        },
        {
          headers: {
            'Content-Type': false,
          },
        },
      )
      .catch((error) => {
        console.error('Error registering to promotion', error);
      });
  }
}
