import {
  Box,
  CircularProgress,
  FileInput,
  Icon,
  ImageBoxPreview,
  Typography,
} from '@worthy-npm/worthy-common-ui-components';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectPhotos } from '../../slices/submitSlice';
import { FILES_MAX_COUNT } from '../../data/constants';

interface IFile {
  status: string;
  kind: 'img' | 'file';
  title: string;
  types: string;
  onFileChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'img' | 'file',
    isError?: boolean,
  ) => void;
  onFileOpen: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const pdf = 'images/icons/pdf.svg';
const plus = 'images/icons/plus.svg';

export default function FileUpload({
  status,
  onFileChange,
  onFileOpen,
  title,
  types,
  kind,
}: IFile) {
  const photos = useAppSelector(selectPhotos);

  const [fileList, setFileList] = useState(photos);

  useEffect(() => {
    setFileList([]);

    const filesList = photos.filter((file) => file.type.includes(kind));

    if (filesList.length) {
      setFileList(filesList);
    }
    return () => {
      setFileList([]);
    };
  }, [photos]);

  const updateFile = (e: any) => {
    const tempFiles = e.target.files;
    const validItems = [...tempFiles].filter((item) => {
      return item.type.match(/pdf|jpeg|png|jpg/);
    });

    const isMax = validItems.length + photos.length > FILES_MAX_COUNT;

    if (isMax || validItems.length < tempFiles.length) {
      e.target.value = null;
      onFileChange(e, kind, true);
      return;
    }

    onFileChange(e, kind);
  };

  const onError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.style.objectFit = 'contain';
    e.currentTarget.src = pdf;
  };

  return (
    <FileInput
      data-testid={`upload-${kind}-input`}
      aria-label={kind}
      data-automation={`upload-${kind}-input`}
      accept={types}
      status={status as any}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.type === 'change' && status !== 'preview') {
          updateFile(e);
        }
      }}
      onClick={(e) => {
        onFileOpen(e);
      }}
      variant="dashed"
      bgcolor="background.paper"
      mb="12px"
      disabled={!['empty'].includes(status) || fileList.length > 8}
      style={{
        padding: 24,
        paddingRight: status === 'preview' && fileList.length > 3 ? '0' : '24px',
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {kind === 'img' && <Icon.CameraIcon fill={status === 'failed' ? '#DE1C22' : '#3A5FE3'} />}
        {kind !== 'img' && <Icon.FileIcon fill={status === 'failed' ? '#DE1C22' : '#3A5FE3'} />}
        <Typography
          variant="button"
          color={status === 'failed' ? 'error.main' : 'primary.main'}
          marginLeft="8px"
        >
          {title}
        </Typography>
      </Box>

      {status === 'empty' && <img src={plus} alt="add" />}
      {status === 'loading' && <CircularProgress size="20px" />}
      {status === 'failed' && <Icon.FailIcon />}
      {status === 'success' && <Icon.GreenCheck />}
      {status === 'preview' && !!fileList.length && (
        <ImageBoxPreview files={fileList as any} onError={onError} />
      )}
    </FileInput>
  );
}
