import React, { useEffect, useRef, useState } from 'react';

import { Step, StepContainer } from '../../../styles/common';
import { useAppDispatch } from '../../../app/hooks';
import { submitItem } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import { isItemRejected } from '../../../validation/validations';

function StepSubmit({ exit, idx, stepName, title, stepCaption, active, props }: StepProps) {
  const dispatch = useAppDispatch();

  const hasRun = useRef(false);

  useEffect(() => {
    const submitData = async () => {
      if (hasRun.current) {
        return;
      }
      hasRun.current = true;
      const status = await dispatch(submitItem());
      const { rejected } = isItemRejected(status.payload?.item);
      if (exit) {
        if (rejected) {
          exit('reject');
        } else {
          exit('next');
        }
      } else {
        throw new Error('no exit');
      }
    };
    submitData();
  }, [active]);

  return (
    <StepContainer data-automation="submit-step">
      <Step>
        <StepTitle
          prev={() => exit && exit('prev')}
          stepTitle=""
          stepCaption=""
          stepNotice=""
          optionalProps={props || {}}
        />
      </Step>
    </StepContainer>
  );
}

export default StepSubmit;
