import React, { useEffect } from 'react';
import CommonLayout from './layout/commonLayout';
import { Wizard2, IStep2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepShape from '../components/steps/common/step_shape';
import { NecklaceStyle, Subsets, JewelryBrand } from '../data/ItemDetails';
import DiamondNumber from '../components/steps/common/step_stoneNumber';
import StepBrand from '../components/steps/common/step_brand';
import TotalWeight from '../components/steps/common/step_totalWeight';
import Weight from '../components/steps/common/step_weight';
import StepRegistration from '../components/steps/common/step_registration';
import { StepAdditionalInfo } from '../components/steps/common/step_additional_info';
import StepStyle from '../components/steps/common/step_style2';
import StepSubmit from '../components/steps/common/step_submit';
import StepReject from '../components/steps/common/step_reject';
import { StyleI } from '../components/steps/common';
import StepMetalType from '../components/steps/step_metalType';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import { loadGraph } from '../lib/wizard';

const CenterStoneStyle = ['Pendant', 'Drop', 'Other'];

export const createNecklaceStyleItems = () => {
  let necklaceStyleItems: StyleI[] = [];

  Object.entries(NecklaceStyle).forEach(([key, value]) => {
    const img =
      value === 'Other'
        ? 'images/icons/other.svg'
        : `images/icons/jewelry/necklace/${value.toLowerCase()}.png`;
    const style = {
      key: `necklace-style-${value}`,
      text: key,
      img,
      code: value,
      secondarySubset: CenterStoneStyle.includes(key)
        ? Subsets.CenterStone
        : Subsets.MinorStoneBatch,
      exitToUse: CenterStoneStyle.includes(key) ? 'centerStone' : 'minorStoneBatch',
    };
    necklaceStyleItems = [...necklaceStyleItems, style];
  });

  return necklaceStyleItems;
};

export const createSteps = (): IStep2[] => [
  {
    comp: StepBrand,
    name: 'StepBrand',
    caption: 'Brand',
    disableBackButton: true,
    title: 'What brand is your necklace?',
    exits: {
      premium: 'StepAdditionalInfo',
      next: 'StepNecklaceStyle',
    },
    props: {
      premiumBrands: [
        JewelryBrand.Cartier,
        JewelryBrand.VanCleefArpens,
        JewelryBrand.Bvlgari,
        JewelryBrand.Tiffany,
        JewelryBrand.HarryWinston,
        JewelryBrand.Graff,
        JewelryBrand.DavidWebb,
        JewelryBrand.Verdura,
        JewelryBrand.Buccellati,
        JewelryBrand.OscarHeyman,
      ],
    },
  },
  {
    comp: StepStyle,
    name: 'StepNecklaceStyle',
    caption: 'Necklace Style',
    title: 'What is the style of your necklace?',
    props: { items: createNecklaceStyleItems() },
    exits: {
      centerStone: 'StepLargestDiamondsShape',
      minorStoneBatch: 'StepNumberOfStones',
    },
  },
  {
    comp: StepShape,
    name: 'StepLargestDiamondsShape',
    caption: 'Diamonds Shape',
    title: 'What is the shape of the largest diamond?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: Weight,
    name: 'StepCaratWeight',
    caption: 'Carat Weight',
    title: 'Great! What is the carat weight of the largest diamond?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: StepMetalType,
    name: 'StepMetalType',
    caption: 'Metal Type',
    title: 'What type of metal is your necklace?',
    secondarySubset: Subsets.CenterStone,
    exits: { next: 'StepSubmit' },
  },
  {
    comp: DiamondNumber,
    name: 'StepNumberOfStones',
    caption: 'Number of Stones',
    title: 'Great! What is the total number of diamonds in your necklace?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: TotalWeight,
    name: 'StepTotalCaratWeight',
    caption: 'Carat Weight',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: StepShape,
    name: 'StepCommonDiamondsShape',
    caption: '',
    title: 'What is the most common shape of the diamonds in your necklace?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: 'StepBrand',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
  {
    comp: StepAdditionalInfo,
    name: 'StepAdditionalInfo',
    caption: 'Additional Info',
    title: 'Do you have the original box and paperwork?',
    exits: {
      next: 'StepSubmit',
    },
  },
];

function NecklacePage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType('necklace'));

  useEffect(() => {
    // default subset for earring to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
  }, [dispatch, steps]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default NecklacePage;
