import React from 'react';
import { Button, Stack, styled } from '@worthy-npm/worthy-common-ui-components';

const svgString = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g id="16px/ ic16_Check 16 px">
    <path id="Vector 35" d="M14 4L6.28571 12L2 8.5" stroke="#2B353C" strokeWidth="2" />
  </g>
</svg>
`;
const svgUrl = `url("data:image/svg+xml,${encodeURIComponent(svgString)}")`;

export const StyledButton = styled(Button)<{ selected?: boolean }>(({ theme, selected }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  alignItems: 'center',
  justifyContent: 'start',
  height: 60,
  padding: theme.spacing(2),
  '&:hover': {
    outline: `1.5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
  },
  outline: selected ? `1.5px solid ${theme.palette.primary.main}` : `none`,
  '&::after': {
    content: selected ? svgUrl : "''",
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '10px',
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
}));

export const StyledList = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export default { StyledButton, StyledList };
