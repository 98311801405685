import React, { useEffect } from 'react';
import CommonLayout from './layout/commonLayout';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepShape from '../components/steps/common/step_shape';
import Weight from '../components/steps/common/step_weight';
import StepRegistration from '../components/steps/common/step_registration';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import { ItemTypes } from '../data/ItemDetails';
import { loadGraph } from '../lib/wizard';
import StepSubmit from '../components/steps/common/step_submit';
import StepReject from '../components/steps/common/step_reject';

const createSteps = (): IStep2[] => [
  {
    comp: StepShape,
    name: 'StepDiamondShape',
    caption: 'Diamond Shape',
    title: 'What is the shape of your diamond?',
    disableBackButton: true,
  },
  {
    comp: Weight,
    name: 'StepCaratWeight',
    caption: 'Carat Weight',
    title: 'Great! And what is the carat weight of your diamond?',
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: 'StepDiamondShape',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
];

function LooseDiamondPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType(ItemTypes.LooseDiamond));

  useEffect(() => {
    // default subset for earring to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
  }, [dispatch, steps]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default LooseDiamondPage;
