/* eslint-disable import/prefer-default-export */
// DISCUSS_ME: Default export is a source for errors.

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Icon,
  FormGroup,
  Checkbox,
  Button,
} from '@worthy-npm/worthy-common-ui-components';
// XXX:
import { NextStepButton, Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch } from '../../../app/hooks';
import { updateBox, updateDocuments } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import {
  BoxSvg,
  ConditionWrapper,
  ContentWrapper,
  FormControlLabelWrap,
  FormLabelWrapper,
  PaperSvg,
} from '../../../styles/premiumInfo.styles';
import CTAButton from '../../common/ContinueButton';

const WAIT = 150;

export function StepAdditionalInfo({
  next,
  active,
  idx,
  stepName,
  prev,
  title,
  stepCaption,
  props,
}: StepProps) {
  const dispatch = useAppDispatch();

  const [busy, setBusy] = useState(false);
  const [withBox, setWithBox] = useState(false);
  const [withDocs, setWithDocs] = useState(false);

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  const onUpdate = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (busy) return;
    setBusy(true);
    const box = withBox ? 'yes' : 'no';
    const docs = withDocs ? 'yes' : 'no';

    dispatch(updateBox(box));
    dispatch(updateDocuments(docs));
    GA.select({ step: stepName, object: 'box', value: box, object2: 'documents', value2: docs });

    setTimeout(() => {
      next(e);
      setTimeout(() => {
        setBusy(false);
      }, WAIT);
    }, WAIT);
  };

  return (
    <StepContainer data-automation="premium-info-step">
      <Step>
        <StepTitle
          stepCaption={stepCaption}
          prev={prev}
          stepTitle={title || 'Do you have the original box and paperwork?'}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <ContentWrapper>
            <ConditionWrapper>
              <FormGroup
                sx={{
                  gap: 1,
                  maxWidth: '330px',
                }}
              >
                <FormControlLabelWrap
                  checked={withBox}
                  labelPlacement="start"
                  data-automation="premium-box-checkbox"
                  control={
                    <Checkbox
                      color="secondary"
                      checked={withBox}
                      onChange={(e) => {
                        setWithBox(e.target.checked);
                      }}
                      sx={{
                        alignSelf: 'flex-start',
                        color: 'secondary.main',
                        '&.Mui-checked': {
                          color: 'inherit',
                        },
                      }}
                    />
                  }
                  label={
                    <FormLabelWrapper>
                      <BoxSvg />
                      <Typography>Original box</Typography>
                    </FormLabelWrapper>
                  }
                />
                <FormControlLabelWrap
                  checked={withDocs}
                  labelPlacement="start"
                  data-automation="premium-docs-checkbox"
                  control={
                    <Checkbox
                      checked={withDocs}
                      onChange={(e) => {
                        setWithDocs(e.target.checked);
                      }}
                      sx={{
                        alignSelf: 'flex-start',
                        color: 'secondary.main',
                        '&.Mui-checked': {
                          color: 'inherit',
                        },
                      }}
                    />
                  }
                  label={
                    <FormLabelWrapper>
                      <PaperSvg />
                      <Typography>Paperwork</Typography>
                    </FormLabelWrapper>
                  }
                />
              </FormGroup>
            </ConditionWrapper>
            <NextStepButton style={{ maxWidth: '149px', width: '100%' }}>
              <CTAButton
                data-automation="premium-next-button"
                onClick={(e) => {
                  onUpdate(e);
                }}
              />
            </NextStepButton>
          </ContentWrapper>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}
