import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepCTAContainer } from '../../../styles/commonText';
import { StepProps } from '../common';
import WorthyAPI from '../../../services/worthyAPI';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  PhotoI,
  selectCarat,
  selectFlow,
  selectIsPremium,
  selectItemType,
  selectPhotos,
  selectSubmitAPI,
  selectValueCategory,
  updatePhotos,
} from '../../../slices/submitSlice';
import GA from '../../../data/GA';
import StepTitle from '../../stepTitle';
import FileUpload from '../../inputs/fileUpload';
import ModalPhotoUpload from './modal_photoUpload';
import { FILES_MAX_COUNT, SUBMISSION_MAX_PHOTO_SIZE_KB } from '../../../data/constants';
import { compress, isPhotoMandatory, toCamelCase } from '../../../lib/commonUtils';
import StyledInfoAlert from '../../common/StyledInfoAlert';
import CTAButton from '../../common/ContinueButton';
import SkipButton from '../../common/SkipButton';

const UploadText = (type: string) => {
  return {
    empty: `Upload ${type}`,
    loading: `Uploading ${type}s...`,
    failed: `${type} Uploading Failed`,
    success: `${type} Uploaded Successfully`,
    preview: `Uploaded ${type}s`,
  };
};

type Status = {
  type: 'file' | 'img';
  status: 'empty' | 'loading' | 'success' | 'preview' | 'failed';
};

const uploadImage = async (itemId: string, images: File[] | Blob[]) => {
  const formData = new FormData();
  [...images].forEach((file, idx) => {
    formData.append(`file_${idx}`, file);
  });

  return WorthyAPI.uploadImage(itemId, formData).catch((e) => {
    console.error(e);
    throw new Error(e);
  });
};

const modalText = {
  cancel: 'Remove All',
  save: 'Add More +',
};

type ModalType = {
  isVisible: boolean;
  type: 'file' | 'img';
};

function StepPhotoUpload({ next, idx, stepName, prev, active, props }: StepProps) {
  const dispatch = useAppDispatch();
  const { itemId, forcePhoto } = useAppSelector(selectSubmitAPI);
  const photos = useAppSelector(selectPhotos);
  const isMobile = useMobileVersion();
  const itemType = useAppSelector(selectItemType);
  const carat = useAppSelector(selectCarat);
  const byImage = useAppSelector(selectFlow);
  const valueCategory = useAppSelector(selectValueCategory);
  const isPremium = useAppSelector(selectIsPremium);

  const [modal, showModal] = useState<ModalType>({ isVisible: false, type: 'img' });
  const [popupAdded, setPopupAdded] = useState(false);
  const [textError, setTextError] = useState('');
  const [imgState, setImgState] = useState({ status: 'empty', title: UploadText('Image').empty });
  const [fileState, setFileState] = useState({
    status: 'empty',
    title: UploadText('Certificate').empty,
  });

  const photoIsMandatory = isPhotoMandatory({ forcePhoto, carat });
  const isMandatoryStep = photoIsMandatory && !byImage;

  let tempCached: PhotoI[] = [];
  const modalClose = (type: 'file' | 'img') => showModal({ isVisible: false, type });

  useEffect(() => {
    const pdfExt = photos.filter((file) => file.type.includes('file'));
    const imgExt = photos.filter((file) => file.type.includes('img'));

    if (!imgExt.length) {
      setImgState({ status: 'empty', title: UploadText('Image').empty });
    }
    if (!pdfExt.length) {
      setFileState({ status: 'empty', title: UploadText('Certificate').empty });
    }
  }, [photos]);

  useEffect(() => {
    if (!active) return;

    GA.stepView(
      stepName,
      'mandatoryStep',
      isMandatoryStep ? 'true' : 'false',
      'valueCategory',
      toCamelCase(valueCategory),
      'ringSubmissionFunnel',
      'photoUploadView',
    );
  }, [active]);

  const clearInput = (type: 'file' | 'img') => {
    if (type === 'img') {
      setImgState({ status: 'empty', title: UploadText('Image').empty });
    } else {
      setFileState({ status: 'empty', title: UploadText('Certificate').empty });
    }
  };

  async function compressFiles(files: File[]): Promise<File[] | Blob[]> {
    const promises: Promise<File | Blob>[] = [];

    [...files].forEach((file) => promises.push(compress(file)));

    return Promise.all(promises);
  }

  const save = async (fileList: File[], fileType: 'file' | 'img') => {
    const tempState = photos.length ? photos : tempCached;

    const data = await compressFiles(fileList);

    try {
      const prevState = [...tempState];
      const { paths } = await uploadImage(itemId, data);
      const newPhotos = paths.reduce<PhotoI[]>(
        (acc, item, id) => [
          ...acc,
          { id: prevState.length + id, src: item, url: item, type: fileType },
        ],
        [],
      );

      tempCached = [...prevState, ...newPhotos];
      dispatch(updatePhotos([...prevState, ...newPhotos])); // upd outer state

      setPopupAdded(false);
      if (fileType === 'img') {
        GA.successfullPhotoUpload();
      } else if (fileType === 'file') {
        GA.successfullCertificateUpload();
      }
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const setStatus = ({ type, status }: Status) => {
    if (type === 'file') {
      setFileState({ status, title: UploadText('Certificate')[status] });
    }
    if (type === 'img') {
      setImgState({ status, title: UploadText('Image')[status] });
    }
  };

  const setError = (fileType: 'img' | 'file') => {
    const filesList = photos.filter((file) => file.type.includes(fileType));

    setStatus({ type: fileType, status: 'failed' });

    setTimeout(() => {
      if (!filesList.length) {
        setStatus({ type: fileType, status: 'empty' });
      } else {
        setStatus({ type: fileType, status: 'preview' });
      }
    }, 1000);
  };

  const upload = async (e: any, fileList: File[], inputType: 'file' | 'img') => {
    const MAX_BYTES = SUBMISSION_MAX_PHOTO_SIZE_KB * 1024;
    let isError = false;
    setStatus({ type: inputType, status: 'loading' });
    try {
      const chunk: File[] = [];
      let count = 0;

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > MAX_BYTES) {
          isError = true;
          setTextError('Upload up to 50MB.');
          break;
        }
        count += fileList[i].size;
        chunk.push(fileList[i]);

        if (count >= MAX_BYTES || i === fileList.length - 1) {
          if (i !== fileList.length - 1) chunk.pop();

          await save(chunk, inputType);
          count = 0;
          chunk.splice(0, chunk.length);
          chunk.push(fileList[i]);
        }
      }

      if (isError) {
        setError(inputType);
      } else {
        setStatus({ type: inputType, status: 'success' });
        setTimeout(() => {
          setStatus({ type: inputType, status: 'preview' });
        }, 400);
      }

      tempCached = [];
    } catch (err) {
      setStatus({ type: inputType, status: 'failed' });

      setTimeout(() => {
        const notEmpty = photos.find((item) => item.type === inputType);

        if (notEmpty) {
          setStatus({ type: inputType, status: 'preview' });
        }
        setStatus({ type: inputType, status: 'empty' });
      }, 1000);

      if (window.rollbar) console.error('Error sending state', err);
    }
  };

  const photoSetAction = async (e: any, fileType: 'img' | 'file', isError = false) => {
    setTextError('');

    const tempFiles = e.target.files;

    const validItems = [...tempFiles].filter((item) => {
      return item.type.match(/pdf|jpeg|png|jpg/);
    });

    const isMax = validItems.length + photos.length > FILES_MAX_COUNT;

    showModal({ isVisible: false, type: fileType });

    if (validItems.length < tempFiles.length) {
      setTextError('Please upload an image or PDF.');
      setError(fileType);
      return;
    }

    if (isMax || isError) {
      setTextError(`Upload up to ${FILES_MAX_COUNT} photos or files.`);
      setError(fileType);
      e.target.value = null;
      return;
    }

    upload(e, tempFiles, fileType);
  };

  const nextClb = () => {
    let photoUploaded = 0;
    let fileUploaded = 0;
    photos.forEach((photo) => {
      if (photo.type === 'img') {
        photoUploaded += 1;
      }
      if (photo.type === 'file') {
        fileUploaded += 1;
      }
    });
    let value: 'none' | 'certificate' | 'photo' | 'photoAndCertificate' = 'none';
    if (photoUploaded || fileUploaded) {
      if (!photoUploaded) {
        value = 'certificate';
      } else if (!fileUploaded) {
        value = 'photo';
      } else {
        value = 'photoAndCertificate';
      }
    }

    GA.continueSelectPhotoUpload({
      value,
      object2: 'valueCategory',
      value2: toCamelCase(valueCategory),
      value4: isMandatoryStep,
    });

    next({} as any);
  };

  const onModalOpen = (e: any, fileType: string) => {
    e.target.value = null;
    const img = photos.length ? photos[0].src : '';
    if (e.type === 'click' && !!img) {
      if (fileType === 'img' && imgState.status === 'preview')
        showModal({ isVisible: true, type: fileType });
      if (fileType === 'file' && fileState.status === 'preview')
        showModal({ isVisible: true, type: fileType });
    }
  };

  return (
    <StepCTAContainer
      isMobile={isMobile}
      data-automation="upload-photo-step"
      data-testid="upload-photo-step"
      style={{ maxHeight: 'none', justifyContent: 'center' }}
    >
      <Step>
        <StepTitle
          stepCaption="Upload Photos"
          prev={prev}
          stepTitle="Upload photos for our experts to review"
          stepNotice={photoIsMandatory ? '' : '(Optional)'}
          optionalProps={props || {}}
        />
        <Box marginTop="32px">
          <Typography
            data-automation="error-template"
            color="error"
            style={{
              textAlign: 'center',
              marginBottom: '32px',
              display: textError ? 'block' : 'none',
            }}
          >
            {textError}
          </Typography>
          <Box margin="0 auto" maxWidth="30em">
            <FileUpload
              aria-label="img"
              kind="img"
              types=".pdf,.jpeg,.png,.jpg"
              onFileChange={photoSetAction}
              onFileOpen={(e) => {
                onModalOpen(e, 'img');
              }}
              status={imgState.status}
              title={imgState.title}
            />
            {itemType === 'Watch' ? null : (
              <FileUpload
                aria-label="file"
                kind="file"
                onFileChange={photoSetAction}
                onFileOpen={(e) => {
                  onModalOpen(e, 'file');
                }}
                status={fileState.status}
                title={fileState.title}
                types=".pdf,.jpeg,.png,.jpg"
              />
            )}

            <StyledInfoAlert sx={{ marginTop: 4 }}>
              Don’t worry about the quality. We’ll take care of the professional shots
            </StyledInfoAlert>
          </Box>
          <ModalPhotoUpload
            modalClose={modalClose}
            isOpen={modal.isVisible}
            type={modal.type}
            photoSetAction={photoSetAction}
            popupAdded={popupAdded}
            clearInput={clearInput}
            text={{
              ...modalText,
              title: modal.type.includes('img') ? 'Upload Image' : 'Upload Certificate',
            }}
          />
        </Box>
      </Step>
      <Box display="flex" justifyContent="center" m={3}>
        {(photoIsMandatory && !isPremium) || !!photos.length ? (
          <CTAButton
            data-automation="photo-next-button"
            data-testid="next-button"
            onClick={nextClb}
            disabled={!photos.length}
          />
        ) : (
          <SkipButton
            data-automation="upload-photo-button"
            data-testid="skip-photo-button"
            onClick={(e) => {
              GA.continueSelectPhotoUpload({
                value: 'none',
                object2: 'valueCategory',
                value2: toCamelCase(valueCategory),
                value4: isMandatoryStep,
              });
              next(e);
            }}
          />
        )}
      </Box>
    </StepCTAContainer>
  );
}

export default StepPhotoUpload;
