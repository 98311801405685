import React from 'react';
import { Alert, Typography, styled } from '@worthy-npm/worthy-common-ui-components';
import { AlertProps } from '@worthy-npm/worthy-common-ui-components/components/Alert/Alert';

// eslint-disable-next-line import/prefer-default-export
const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabled,
  '& .MuiAlert-icon': {
    color: theme.palette.primary.main,
  },
}));

export default function StyledInfoAlert({ children, ...props }: AlertProps) {
  return (
    <StyledAlert {...props} variant="standard" severity="info">
      <Typography variant="caption" color="primary">
        {children}
      </Typography>
    </StyledAlert>
  );
}
