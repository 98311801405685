import React, { useEffect } from 'react';
import CommonLayout from './layout/commonLayout';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepBrand from '../components/steps/common/step_brand';
import StepRegistration from '../components/steps/common/step_registration';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepCondition from '../components/steps/common/step_condition';
import StepModel from '../components/steps/common/step_model';
import { StepAdditionalInfo } from '../components/steps/common/step_additional_info';
import StepSubmit from '../components/steps/common/step_submit';
import StepReject from '../components/steps/common/step_reject';
import { loadGraph } from '../lib/wizard';
import { WatchBrand } from '../data/ItemDetails';

export const createSteps = (): IStep2[] => [
  {
    comp: StepBrand,
    name: 'StepBrand',
    caption: 'Watch Brand',
    title: 'What is the brand of your watch?',
    disableBackButton: true,
    props: {
      premiumBrands: [
        WatchBrand.Rolex,
        WatchBrand.Breitling,
        WatchBrand.Cartier,
        WatchBrand.Iwc,
        WatchBrand.Omega,
        WatchBrand.PatekPhilippe,
        WatchBrand.AudemarsPiguet,
        WatchBrand.RichardMille,
        WatchBrand.Paneral,
        WatchBrand.Breguel,
        WatchBrand.VacheronConstantin,
        WatchBrand.Chopard,
      ],
    },
    exits: {
      premium: 'StepModelPremium',
      next: 'StepModel',
    },
  },
  {
    comp: StepModel,
    name: 'StepModel',
    caption: 'Watch Model',
    title: 'What is the model of your watch?',
    exits: {
      next: 'StepCertificate',
    },
  },
  {
    comp: StepModel,
    name: 'StepModelPremium',
    caption: 'Watch Model',
    title: 'What is the model of your watch?',
    exits: {
      next: 'StepAdditionalInfo',
    },
  },
  {
    comp: StepCondition,
    name: 'StepCertificate',
    caption: 'Condition',
    title: 'What is the condition of your watch?',
    exits: {
      next: 'StepAdditionalInfo',
    },
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disableBackButton: true,
    exits: {
      restart: 'StepBrand',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    itemRequired: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
  {
    comp: StepAdditionalInfo,
    name: 'StepAdditionalInfo',
    caption: 'Additional Info',
    title: 'Do you have the original box and paperwork?',
    exits: {
      next: 'StepSubmit',
    },
  },
];

function WatchPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType('watch'));

  useEffect(() => {
    dispatch(updateSecondarySubset('luxury_watch_watches'));
    dispatch(updateWizardStepName(steps[0].name));
  }, [dispatch, steps]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default WatchPage;
