const parseUtmParams = () => {
  /* eslint-disable-next-line compat/compat */
  const searchParams = new URLSearchParams(window.location.search);
  return {
    utmSource: searchParams.get('utm_source'),
    utmMedium: searchParams.get('utm_medium'),
    utmCampaign: searchParams.get('utm_campaign'),
    utmTerm: searchParams.get('utm_term'),
    utmContent: searchParams.get('utm_content'),
    utmReferrer: searchParams.get('utm_referrer'),
    originalUvid: searchParams.get('uvid'),
    originalReferrer: searchParams.get('referrer'),
    referrer: document.referrer,
  };
};

export const UTMDATA = parseUtmParams();
