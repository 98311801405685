import React, { useEffect } from 'react';
import CommonLayout from './layout/commonLayout';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepShape from '../components/steps/common/step_shape';
import { EarringsStyle, JewelryBrand, Subsets } from '../data/ItemDetails';
import DiamondNumber from '../components/steps/common/step_stoneNumber';
import StepBrand from '../components/steps/common/step_brand';
import TotalWeight from '../components/steps/common/step_totalWeight';
import Weight from '../components/steps/common/step_weight';
import StepRegistration from '../components/steps/common/step_registration';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepStyle from '../components/steps/common/step_style2';
import StepSubmit from '../components/steps/common/step_submit';
import StepReject from '../components/steps/common/step_reject';
import { StepAdditionalInfo } from '../components/steps/common/step_additional_info';
import { loadGraph } from '../lib/wizard';

export const earringsStyleItems = [
  {
    text: 'Drop',
    img: 'images/icons/drop.png',
    code: EarringsStyle.Drop,
    secondarySubset: Subsets.CenterStone,
    exitToUse: 'centerStone',
  },
  {
    text: 'Stud',
    img: 'images/icons/stud.png',
    code: EarringsStyle.Stud,
    secondarySubset: Subsets.CenterStone,
    exitToUse: 'centerStone',
  },
  {
    text: 'Jacket',
    img: 'images/icons/jacket.png',
    code: EarringsStyle.Jacket,
    secondarySubset: Subsets.MinorStoneBatch,
    exitToUse: 'minorStoneBatch',
  },
  {
    text: 'Central Cluster',
    img: 'images/icons/central_cluster.png',
    code: EarringsStyle.CentralCluster,
    secondarySubset: Subsets.CenterStone,
    exitToUse: 'centerStone',
  },
  {
    text: 'Blossom Cluster',
    img: 'images/icons/blossom_cluster.png',
    code: EarringsStyle.BlossomCluster,
    secondarySubset: Subsets.MinorStoneBatch,
    exitToUse: 'minorStoneBatch',
  },
  {
    text: 'Chandelier',
    img: 'images/icons/chandelier.png',
    code: EarringsStyle.Chandelier,
    secondarySubset: Subsets.MinorStoneBatch,
    exitToUse: 'minorStoneBatch',
  },
  {
    text: 'Hoops',
    img: 'images/icons/hoops.png',
    code: EarringsStyle.Hoops,
    secondarySubset: Subsets.MinorStoneBatch,
    exitToUse: 'minorStoneBatch',
  },
  {
    text: 'Huggies',
    img: 'images/icons/huggie.png',
    code: EarringsStyle.Huggie,
    secondarySubset: Subsets.MinorStoneBatch,
    exitToUse: 'minorStoneBatch',
  },
  {
    code: EarringsStyle.Other,
    secondarySubset: Subsets.CenterStone,
    exitToUse: 'centerStone',
    img: 'images/icons/other.svg',
    text: 'Other',
  },
];

export const createSteps = (): IStep2[] => [
  {
    comp: StepBrand,
    name: 'StepBrand',
    caption: 'Brand',
    disableBackButton: true,
    title: 'What brand is your earrings?',
    exits: {
      premium: 'StepAdditionalInfo',
      next: 'StepEarringsStyle',
    },
    props: {
      premiumBrands: [
        JewelryBrand.Cartier,
        JewelryBrand.VanCleefArpens,
        JewelryBrand.Bvlgari,
        JewelryBrand.Tiffany,
        JewelryBrand.HarryWinston,
        JewelryBrand.Graff,
        JewelryBrand.DavidWebb,
        JewelryBrand.Verdura,
        JewelryBrand.Buccellati,
        JewelryBrand.OscarHeyman,
      ],
    },
  },
  {
    comp: StepStyle,
    name: 'StepEarringsStyle',
    caption: 'Earrings Style',
    title: 'What is the style of your earrings?',
    props: { items: earringsStyleItems },
    exits: {
      centerStone: 'StepLargestDiamondsShape',
      minorStoneBatch: 'StepNumberOfStones',
    },
  },
  {
    comp: StepShape,
    name: 'StepLargestDiamondsShape',
    caption: 'Diamonds Shape',
    title: 'What is the shape of the largest diamond?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: DiamondNumber,
    name: 'StepNumberOfStones',
    caption: 'Number of Stones',
    title: 'Great! How many diamonds are set in your earring?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: TotalWeight,
    name: 'StepTotalCaratWeight',
    caption: 'Carat Weight',
    title: 'What is the total carat weight?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: StepShape,
    name: 'StepCommonDiamondsShape',
    caption: 'Diamonds Shape',
    title: 'What is the most common diamond shape in your earrings?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: Weight,
    name: 'StepCaratWeight',
    caption: 'Carat Weight',
    title: 'Great! What is the largest diamond’s carat weight?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: 'StepBrand',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
  {
    comp: StepAdditionalInfo,
    name: 'StepAdditionalInfo',
    caption: 'Additional Info',
    title: 'Do you have the original box and paperwork?',
    exits: {
      next: 'StepSubmit',
    },
  },
];

function EarringsPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType('earrings'));

  useEffect(() => {
    // default subset for earrings to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
  }, [dispatch, steps]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default EarringsPage;
