import React, { useEffect, useState } from 'react';
import { Typography, Icon, Stack } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, selectShape, updateShape } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { DiamondShape } from '../../../data/ItemDetails';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import { StyledButton, StyledList } from '../../common/ButtonsList';

const ShapeRoundIcon = Icon.createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 32C25.732 32 32 25.732 32 18C32 10.268 25.732 4 18 4C10.268 4 4 10.268 4 18C4 25.732 10.268 32 18 32Z"
      stroke="CurrentColor"
      strokeWidth="1.55"
      strokeMiterlimit="10"
    />
  </svg>,
  'ShapeRound',
);
const ShapePrincessIcon = Icon.createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.25 4H5.75C4.7835 4 4 4.7835 4 5.75V30.25C4 31.2165 4.7835 32 5.75 32H30.25C31.2165 32 32 31.2165 32 30.25V5.75C32 4.7835 31.2165 4 30.25 4Z"
      stroke="CurrentColor"
      strokeWidth="1.55"
      strokeMiterlimit="10"
    />
  </svg>,
  'ShapePrincess',
);
const ShapeCushionIcon = Icon.createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5 4H13C9.13401 4 6 7.13401 6 11V25C6 28.866 9.13401 32 13 32H23.5C27.366 32 30.5 28.866 30.5 25V11C30.5 7.13401 27.366 4 23.5 4Z"
      stroke="CurrentColor"
      strokeWidth="1.55"
      strokeMiterlimit="10"
    />
  </svg>,
  'ShapeCushion',
);
const ShapeMarquiseIcon = Icon.createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.7738 18C25.7738 23.9225 21.884 29.0318 19.7497 31.3971C19.0198 32.201 17.754 32.201 17.0241 31.3971C14.8898 29.0411 11 23.9317 11 18C11 12.0683 14.8898 6.96816 17.0241 4.60287C17.754 3.79904 19.0198 3.79904 19.7497 4.60287C21.884 6.95892 25.7738 12.0683 25.7738 18Z"
      stroke="CurrentColor"
      strokeWidth="1.55"
      strokeMiterlimit="10"
    />
  </svg>,
  'ShapeMarquise',
);
const ShapeEmeraldIcon = Icon.createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.075 4H12.925C12.4613 4 12.015 4.18375 11.6913 4.51625L4.51626 11.6913C4.18376 12.0238 4 12.4613 4 12.925V23.075C4 23.5388 4.18376 23.985 4.51626 24.3088L11.6913 31.4838C12.0238 31.8163 12.4613 32 12.925 32H23.075C23.5388 32 23.985 31.8163 24.3088 31.4838L31.4838 24.3088C31.8163 23.9763 32 23.5388 32 23.075V12.925C32 12.4613 31.8163 12.015 31.4838 11.6913L24.3088 4.51625C23.9763 4.18375 23.5388 4 23.075 4Z"
      stroke="CurrentColor"
      strokeWidth="1.55"
      strokeMiterlimit="10"
    />
  </svg>,
  'ShapeEmerald',
);
const ShapeOtherIcon = Icon.createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4485 4.01223L19.8379 10.8896C19.9613 11.1364 20.1916 11.3009 20.4549 11.342L28.0397 12.4444C28.7142 12.5431 28.9857 13.374 28.4921 13.8511L23.0051 19.1983C22.8076 19.3875 22.7254 19.659 22.7665 19.9305L24.0663 27.4824C24.1814 28.157 23.474 28.667 22.8734 28.3462L16.0866 24.7759C15.848 24.6525 15.5601 24.6525 15.3215 24.7759L8.53467 28.3462C7.93414 28.667 7.22666 28.1487 7.34183 27.4824L8.64162 19.9305C8.69098 19.6672 8.60048 19.3875 8.40304 19.1983L2.91598 13.8511C2.43061 13.374 2.69386 12.5431 3.36844 12.4444L10.9533 11.342C11.2247 11.3009 11.4551 11.1364 11.5702 10.8896L14.9596 4.01223C15.2639 3.40347 16.1359 3.40347 16.4321 4.01223H16.4485Z"
      stroke="CurrentColor"
      strokeWidth="1.55"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'ShapeOther',
);
const Shapes = [
  {
    text: DiamondShape.Round,
    img: ShapeRoundIcon,
    code: DiamondShape.Round,
  },
  {
    text: DiamondShape.Princess,
    img: ShapePrincessIcon,
    code: DiamondShape.Princess,
  },
  {
    text: DiamondShape.Cushion,
    img: ShapeCushionIcon,
    code: DiamondShape.Cushion,
  },
  {
    text: DiamondShape.Marquise,
    img: ShapeMarquiseIcon,
    code: DiamondShape.Marquise,
  },
  {
    text: DiamondShape.Emerald,
    img: ShapeEmeraldIcon,
    code: DiamondShape.Emerald,
  },
  {
    text: DiamondShape.Other,
    img: ShapeOtherIcon,
    code: DiamondShape.Other,
  },
];

function StepShape({ active, next, idx, stepName, prev, title, stepCaption, props }: StepProps) {
  const dispatch = useAppDispatch();
  const shape = useAppSelector(selectShape);
  const itemType = useAppSelector(selectItemType);

  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!active) return;

    if (itemType === 'Ring') {
      GA.stepView(
        stepName,
        undefined,
        undefined,
        undefined,
        undefined,
        'ringSubmissionFunnel',
        'shapeStepView',
      );
    } else {
      GA.stepView(stepName);
    }
  }, [active]);

  return (
    <StepContainer data-automation="shape-step">
      <Step>
        <StepTitle
          prev={prev}
          stepCaption={stepCaption}
          stepTitle={title || 'What is the shape of the largest diamond?'}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <StyledList>
            {Shapes.map((item) => (
              <StyledButton
                data-automation={`shape-step-item-${parametrize(item.code)}`}
                key={item.code}
                selected={item.code === shape}
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  if (itemType === 'Ring') {
                    GA.select({ step: stepName, value: item.code, value3: 'shapeStepNext' });
                  } else {
                    GA.select({ step: stepName, value: item.code });
                  }
                  dispatch(updateShape(item.code));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <item.img sx={{ fontSize: 32 }} />
                  <Typography variant="body1" textTransform="capitalize">
                    {item.text}
                  </Typography>
                </Stack>
              </StyledButton>
            ))}
          </StyledList>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepShape;
