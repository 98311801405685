import { QUALIFICATION_REJECTED } from '../data/constants';

export const removeSpaces = (val: string) => val.trim().replace(/\s+/g, '');
export const castToCarat = (val: string) => parseFloat(removeSpaces(val));
// eslint-disable-next-line max-len
export const castToGIANumber = (val: string) => parseInt(removeSpaces(val).substring(0, 10), 10);

export const isValidCaratValue = (value: string) => {
  const val = value.trim();
  const regexPositiveNum = /^\d*(\.\d*)?$/;
  const test = regexPositiveNum.test(val);
  return val.length > 0 && test && Number(val) >= 0.001;
};

export const isValidGIACertificateNumberValue = (value: string) => {
  const regex = /^\d{8,10}/;

  return regex.test(value) && value.length >= 8 && value.length <= 10;
};

export const checkRegistrationFields = (
  resultState: any,
  options: {
    verificationView: boolean;
    forcePhoneNumber: boolean;
  },
) => {
  const state = { ...resultState };
  const validationRules: {
    ['firstName']: any;
    ['lastName']: any;
    ['email']: any;
    ['phoneNumber']: any;
  } = {
    firstName(str: string) {
      const name = str.trim();
      return options.verificationView || (name.length > 1 && /^[A-Za-z'\- ]+$/.test(name));
    },
    lastName(str: string) {
      const name = str.trim();
      return options.verificationView || (name.length > 1 && /^[A-Za-z'\- ]+$/.test(name));
    },
    email: (str: string) => {
      const email = str.trim();
      // @ts-ignore
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return options.verificationView || re.test(email);
    },
    phoneNumber: (str: string) => {
      const phone = str.trim();
      const phoneWithoutSpecialCharacters = phone.replace(/[.\-+() ]/g, '');
      const re = /^1?[2-9]{1}[0-9]{9}$/;
      return (
        re.test(phoneWithoutSpecialCharacters) || (!options.forcePhoneNumber && phone.length === 0)
      );
    },
  };

  const res = Object.entries(resultState)
    .map((entry: any) => {
      const [k, v] = entry;
      // @ts-ignore
      if (typeof validationRules[k as string] !== 'function') {
        // eslint-disable-next-line no-console
        console.warn(`Such field validation is not supported "${k}"`);
        return false;
      }
      // @ts-ignore
      const isValid = validationRules[k](v.value);
      // @ts-ignore
      state[k] = {
        value: v.value,
        isValid,
      };
      return isValid;
    })
    .every((value) => value === true);

  return [res, state];
};

export const isItemRejected = (item: any): { rejected: boolean; rejectReason: string } => {
  if (item?.item_type === 'watch') {
    return { rejected: false, rejectReason: '' };
  }
  const rejected = item?.submission_qualification === QUALIFICATION_REJECTED;
  let rejectReason = 'estimation';
  if (item?.submission_qualification_reason) {
    rejectReason = item.submission_qualification_reason.includes('properties')
      ? 'properties'
      : 'estimation';
  }

  return { rejected, rejectReason };
};
