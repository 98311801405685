/* eslint-disable import/prefer-default-export */
import WorthyAPI from '../services/worthyAPI';

export const generateEstimation = async (
  itemId: string,
  measureByImg: boolean,
): Promise<Record<string, any>> => {
  try {
    const result = await WorthyAPI.generateItemEstimationByPublicId(itemId, measureByImg);
    const { item } = result;
    if (!item) {
      throw new Error('item missing after generateEstimation');
    }

    return result;
  } catch (error) {
    window?.rollbar?.error(`generate estimation API failed: ${error}`);
    return {};
  }
};
