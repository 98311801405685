import React from 'react';
import styled from '@emotion/styled/macro';
import { IsMobileProp } from '../../styles/commonPropType';
import { RunningText } from '../../styles/commonText';

export interface StyleI {
  text: string;
  img: string;
  code: string;
  secondarySubset: string;
  exitToUse?: string;
}

export interface OptionalStepPropsI {
  items?: any;
  premiumBrands?: string[];
  disableBackButton?: boolean;
  fontSize?: string;
  fontFamily?: string;
}

export interface StepProps {
  idx: number;
  stepName: string;
  title?: string;
  stepCaption: string;
  next: React.MouseEventHandler<HTMLElement>;
  prev: React.MouseEventHandler<HTMLElement>;
  exit?: (name: string) => void;
  props?: OptionalStepPropsI;
  active: boolean;
}

export const ErrorMessage = styled(RunningText)`
  color: red;
  margin: 10px;
`;

export const InputItem = styled.div<IsMobileProp>`
  max-width: ${(props: any) => (props.isMobile ? 350 : 280)}px;
  width: 100%;
  height: 65px;
  & input,
  label {
    font: 16px 'Roboto' !important;
    font-weight: 300 !important;
  }
  & input,
  input:hover {
    border: 1px solid #2d5dec;
    border-radius: 4px;
  }
  & input:focus {
    border: 2px solid #2d5dec;
    border-radius: 4px;
  }
  &:focus-within label {
    color: #2d5dec !important;
  }
`;

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  aspect-ratio: 2 / 1;
  width: 100%;
  object-fit: contain;
`;

export const FormWrap = styled.div`
  overflow: hidden;
`;

export interface IFormContainerProps {
  active: boolean;
}

export const FormContent = styled.div<IFormContainerProps>`
  position: relative;
  height: ${(props) => (props.active ? '150px' : '0')};
  top: ${(props) => (props.active ? '0px' : '-150px')};
  transition: all 0.5s ease;
`;

export const Notice = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #999999;
  text-align: center;
  margin: 32px 0 8px 0;
`;
