import React, { useEffect, useState } from 'react';
import { List, Item, Typography, Box, Icon } from '@worthy-npm/worthy-common-ui-components';
import { StepContainer, StepGridContainer, Step } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  updateSecondarySubset,
  updateStyle,
  selectStyle,
  selectItemType,
} from '../../../slices/submitSlice';
import { StepProps, StyleI } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';

function StepStyle({ exit, idx, stepName, title, stepCaption, active, props }: StepProps) {
  const dispatch = useAppDispatch();
  const style = useAppSelector(selectStyle);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  const items: StyleI[] = props?.items ?? [];

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  return (
    <StepContainer data-automation="style-step">
      <Step id="step">
        <StepTitle
          prev={() => exit && exit('prev')}
          stepTitle={title || `What is the style of your ${itemType.toLowerCase()}?`}
          stepCaption={stepCaption}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <List>
            {items.map((item: StyleI, index: number) => (
              <Item
                data-automation={`style-step-item-${parametrize(item.code)}`}
                id={`style-step-item-${parametrize(item.code)}`}
                key={item.code}
                variant="outlined"
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select({ step: stepName, value: item.code });
                  dispatch(updateStyle(item.code));
                  dispatch(updateSecondarySubset(item.secondarySubset));
                  setTimeout(() => {
                    if (exit) exit(item.exitToUse || 'error');
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
                style={{ cursor: 'pointer', padding: '10px 14px', marginTop: '8px' }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  height={index === items.length - 1 ? '65px' : 'initial'}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={item.img}
                      alt={item.text}
                      style={
                        index === items.length - 1
                          ? { height: '30px', margin: '0 16px 0 18px' }
                          : { height: '65px' }
                      }
                    />
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="15px"
                      textTransform="capitalize"
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  <Box data-automation="style-step-item-checked">
                    {item.code === style ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </Item>
            ))}
          </List>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepStyle;
