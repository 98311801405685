import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import submitReducer from '../slices/submitSlice';

export const createStore = () =>
  configureStore({
    reducer: {
      submit: submitReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // // Ignore these action types:
          // ignoredActions: ['your/action/type'],
          // Ignore these field paths in all actions:
          ignoredActionPaths: ['payload.stepsGraph'],
          // // Ignore these paths in the state:xs
          // ignoredPaths: ['items.dates'],
        },
      }),
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
