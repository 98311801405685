const { REACT_APP_DEFAULT_ESTIMATION, REACT_APP_DEFAULT_ESTIMATION_WATCH } = process.env;

export const suggestCaratThreshold = 5;

export const SUBMISSION_MAX_PHOTO_SIZE_KB = 50000;
export const FILES_MAX_COUNT = 4;
export const QUALIFICATION_REJECTED = 2;

export enum QUALIFICATION {
  APPROVED,
  PENDING,
  REJECTED,
}

export const VALUE_CATEGORY = {
  SUPER_EXPENSIVE: 'Super Expensive',
};

export const ESTIMATION_DEFAULT = {
  WATCH: +(REACT_APP_DEFAULT_ESTIMATION_WATCH || 0),
  OTHER: +(REACT_APP_DEFAULT_ESTIMATION || 0),
};

export default { suggestCaratThreshold };
