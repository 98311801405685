import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { StepProps } from '../common';
import GA from '../../../data/GA';
import ItemRejection from '../../itemRejection';

function StepReject({ exit, idx, stepName, title, stepCaption, active, props }: StepProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (active) GA.stepView(stepName);
  }, [active]);

  return <ItemRejection />;
}

export default StepReject;
