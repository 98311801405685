import * as React from 'react';
import { styled } from '@worthy-npm/worthy-common-ui-components';

const LoadingDiamondAnimation = '/images/loaders/loader_off_white.gif';

export function LoadingDiamondImg() {
  return <img data-automation="loading-overlay" src={LoadingDiamondAnimation} alt="loading" />;
}

export const LoadingContainer = styled('div')`
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const LoadingOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  opacity: 0.9,
  color: '#000',
  background: theme.palette.background.default,
  zIndex: 5,
  filter: 'saturation(40%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '200%',
}));
