import React, { useEffect, useState } from 'react';
import { Typography } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepContainer, StepGridContainer } from '../../styles/common';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectMetalType, updateMetalType } from '../../slices/submitSlice';
import { StepProps } from './common';
import { MetalTypes } from '../../data/ItemDetails';
import StepTitle from '../stepTitle';
import { parametrize } from '../../lib/commonUtils';
import GA from '../../data/GA';
import { StyledButton, StyledList } from '../common/ButtonsList';

const Items = Object.values(MetalTypes);

function StepMetalType({
  active,
  next,
  idx,
  stepName,
  prev,
  title,
  stepCaption,
  props,
}: StepProps) {
  const dispatch = useAppDispatch();
  const metalType = useAppSelector(selectMetalType);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  return (
    <StepContainer data-automation="metal-type-step">
      <Step>
        <StepTitle
          prev={prev}
          stepTitle={title || `What metal is your ${itemType.toLowerCase()} made of?`}
          stepNotice="Select an option below"
          stepCaption={stepCaption}
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <StyledList>
            {Items.map((item: string) => (
              <StyledButton
                data-automation={`metal-type-step-item-${parametrize(item)}`}
                key={item}
                selected={metalType === item}
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select({ step: stepName, value: item });
                  dispatch(updateMetalType(item));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
              >
                <Typography variant="body1" textTransform="capitalize">
                  {item}
                </Typography>
              </StyledButton>
            ))}
          </StyledList>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepMetalType;
